import React from 'react';
import { graphql, navigate, PageProps } from 'gatsby';

// components
import { Layout, Seo, Title, PageContent, StreamEmbed } from '@/components';

/**
 * The site's Livestream page
 */
const LivestreamPage = ({ data }: PageProps<Queries.LivestreamPageQuery>) => {
  // if the page cannot be found on the backend, navigate to 404 to avoid an empty page
  if (!data.wpPage) {
    navigate('/404/');
    return null;
  }

  const { uri, title } = data.wpPage;
  const { currentlyStreaming, livestreamUrl, notStreamingMessage } =
    data?.wpPage?.livestream || {};

  const streamContent =
    !!currentlyStreaming && !!livestreamUrl ? (
      <StreamEmbed stream={livestreamUrl} />
    ) : (
      <div
        dangerouslySetInnerHTML={{
          __html:
            notStreamingMessage ||
            'Sorry, we are not livestreaming right now. Please try back later.',
        }}
      />
    );

  return (
    <Layout withSidebar>
      <Seo title={title} pathname={uri} />
      <PageContent>
        <Title>{title}</Title>
        {streamContent}
      </PageContent>
    </Layout>
  );
};

export default LivestreamPage;

// page query
export const query = graphql`
  query LivestreamPage {
    wpPage(uri: { eq: "/livestream/" }) {
      uri
      title
      livestream {
        currentlyStreaming
        livestreamUrl
        notStreamingMessage
      }
    }
  }
`;
